import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { RedemptionInfo } from '@/types/Storefront'
import api from '@/util/api'
import { useAuthStore } from './AuthStore'
import { BasketItem } from './BulkTransferStore'
import { UserAddress } from '@/types/Forms'

export const useRedemptionStore = defineStore('redemption', () => {
	const authStore = useAuthStore()
	const redemptionInfo = reactive<RedemptionInfo>({
		user: null,
		assets: [],
		shippingInfo: {
			shipping_name: '',
			address_line1: '',
			address_line2: '',
			address_line3: '',
			city_locality: '',
			state_region: '',
			zip_postal_code: '',
			country: 'US',
			phone: '',
		},
	})

	loadShippingInfo()

	async function redeemAssets(
		assets?: BasketItem[],
	): Promise<{ success: boolean; message: string; code: number | string | null; sid?: string | null }> {
		if (!redemptionInfo.user && authStore.user) {
			redemptionInfo.user = {
				id: authStore.user.id,
				username: authStore.user.username,
				email: authStore.user.email,
			}
		}

		if (assets?.length) {
			redemptionInfo.assets = assets.map((asset) => ({
				storefront_id: asset.asset.storefront_id,
				asset_id: asset.asset_id,
				name: asset.asset.name,
				quantity: asset.quantity as number,
			}))
		}

		return api.redeemAsset(redemptionInfo)
	}

	function resetRedemptionAssets() {
		redemptionInfo.assets = []
	}

	function updateShippingInfo(userShippingInfo: UserAddress) {
		redemptionInfo.shippingInfo = userShippingInfo
		try {
			localStorage.setItem('userShippingInfo', JSON.stringify(userShippingInfo))
		} catch (error) {
			console.error('Failed to store shipping info locally', error)
		}
	}

	function loadShippingInfo() {
		try {
			const userShippingInfoString = localStorage.getItem('userShippingInfo')
			if (userShippingInfoString) {
				const {
					shipping_name,
					address_line1,
					address_line2,
					address_line3,
					city_locality,
					state_region,
					zip_postal_code,
					country,
					phone,
				} = JSON.parse(userShippingInfoString)

				const shippingInfo: UserAddress = {
					shipping_name,
					address_line1,
					address_line2,
					address_line3,
					city_locality,
					state_region,
					zip_postal_code,
					country,
					phone,
				}

				redemptionInfo.shippingInfo = shippingInfo
			}
		} catch (error) {
			console.error('Failed to load shipping info from local storage')
		}
	}

	return {
		redemptionInfo,
		redeemAssets,
		resetRedemptionAssets,
		updateShippingInfo,
	}
})
