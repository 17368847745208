<template>
	<modal>
		<template v-if="!props.asset" #default> waiting </template>
		<template v-else #default>
			<div class="bg-white rounded-xl drop-shadow-md w-full max-h-[80vh] flex flex-col">
				<div v-if="!state.transfer.txComplete">
					<div class="p-6 border-b flex justify-between">
						<p class="text-2xl font-bold">Redeem For Physical Item</p>
						<button
							@click.stop="close"
							class="text-gray-500 absolute top-4 right-4 text-xl"
							aria-label="Close redemption modal"
						>
							<i class="fas fa-close"></i>
						</button>
					</div>

					<section v-if="state.transfer.txInProgress" class="p-6">Submitting redemption request...</section>

					<section v-else>
						<div class="p-6 pb-0">
							<div class="p-4 border bg-slate-100 rounded-lg flex items-center">
								<div class="mt-1 mr-4 flex h-16 w-16 shrink-0 border rounded-lg">
									<img
										:src="$cdnify(props.asset?.image, 128)"
										:alt="props.asset?.name"
										class="mx-auto"
									/>
								</div>
								<div>
									<p class="font-semibold">{{ props.asset.name }}</p>
									<p class="text-sm">{{ props.asset.storefront.name }}</p>
								</div>
							</div>
						</div>

						<div class="p-6 grow flex flex-col bg-white">
							<div v-show="!state.showConfirmAddress" class="flex flex-col space-y-4 mb-4">
								<p class="mb-8">
									You are redeeming your token for a physical item. Please enter your shipping address
									(no PO Boxes) to receive your item.
								</p>
								<form-input-address
									label="Shipping Information"
									:address="redemptionStore.redemptionInfo.shippingInfo"
									:transfer-state="state.transfer"
									@update-address="
										(address) => (redemptionStore.redemptionInfo.shippingInfo = address)
									"
									@update-transfer-state="(transferState) => (state.transfer = transferState)"
								/>
							</div>

							<div v-show="state.showConfirmAddress">
								<p class="mb-8">
									Please confirm your shipping information carefully before proceeding. Once
									submitted, your token will be sent to our Redemption Team for processing, and
									incorrect addresses may not be correctable before shipment.
								</p>
								<div class="p-4 bg-gray-100 rounded-lg">
									<p class="font-semibold">Shipping Information:</p>

									<p>{{ redemptionStore.redemptionInfo.shippingInfo.shipping_name }}</p>
									<address>
										<span
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line1 }}<br
										/></span>
										<span v-if="redemptionStore.redemptionInfo.shippingInfo.address_line2"
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line2 }}<br
										/></span>
										<span v-if="redemptionStore.redemptionInfo.shippingInfo.address_line3"
											>{{ redemptionStore.redemptionInfo.shippingInfo.address_line3 }}<br
										/></span>
										<span
											>{{ redemptionStore.redemptionInfo.shippingInfo.city_locality }},
											{{ redemptionStore.redemptionInfo.shippingInfo.state_region }}
											{{ redemptionStore.redemptionInfo.shippingInfo.zip_postal_code }}<br
										/></span>
										<span>{{ redemptionStore.redemptionInfo.shippingInfo.country }}<br /></span>

										<span>{{ redemptionStore.redemptionInfo.shippingInfo.phoneMasked }}</span>
									</address>
								</div>
								<button
									class="btn-light mt-4"
									@click="
										() => {
											state.showConfirmAddress = false
											state.showConfirmWarning = false
										}
									"
								>
									Edit Address
								</button>
							</div>

							<div v-if="state.showConfirmWarning">
								<div class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2">
									<p class="font text-2xl">
										<span
											class="inline-flex justify-center items-center px-3 bg-red-600 text-white text-xl aspect-square rounded-full leading-none mr-2"
										>
											<i class="fa fa-exclamation"></i>
										</span>
										Warning
									</p>
									<p class="text-base">
										This action <strong class="uppercase">cannot</strong> be undone. Make sure your
										Shipping Address is correct and that you know you will not see your token in
										your collections anymore after before pressing <em>Redeem</em> below.
									</p>
								</div>
							</div>

							<div
								v-if="state.transfer.txError"
								class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2"
							>
								<p class="font text-base">
									<span
										class="inline-flex justify-center items-center px-2 bg-red-600 text-xs text-white aspect-square rounded-full leading-none"
									>
										<i class="fa fa-exclamation"></i>
									</span>
									Transaction Error
								</p>
								<p class="text-base">
									{{ state.transfer.txError }}
								</p>
							</div>
						</div>
					</section>
				</div>

				<div v-if="state.transfer.txComplete">
					<div class="p-6 border-b">
						<p class="text-2xl font-bold">Redemption Request Success!</p>
					</div>
					<section class="p-6 flex flex-col items-center">
						<p class="text-xl font-semibold">You've taken the first step to redeem your item!</p>
						<p class="text-gray-500 mt-2">
							Look out for tracking information from our Redemption Team this upcoming Tuesday ({{
								upcomingTuesdayDate
							}}).
						</p>
					</section>
				</div>

				<div class="bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6 mt-auto rounded-b-xl">
					<button
						v-if="!state.transfer.txInProgress && !state.transfer.txComplete"
						class="text-gray-500"
						@click.stop="close"
					>
						Cancel
					</button>

					<button
						v-if="!state.transfer.txComplete"
						@click.stop="redeemAsset"
						class="btn-primary-lg text-white space-x-1"
						:disabled="!isRedemptionInfoValid || state.transfer.txInProgress"
					>
						<span v-if="!state.transfer.txInProgress">
							<i class="fa-solid fa-paper-plane" />
							{{ !state.showConfirmAddress ? 'Confirm Address' : 'Redeem' }}
						</span>
						<span v-else>
							<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
						</span>
					</button>

					<button v-if="state.transfer.txComplete" @click="close" class="btn-primary-lg">Done</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, onMounted, reactive, onUnmounted } from 'vue'
import { Asset } from '@/types/Asset'
import { AxiosError } from 'axios'
import { TransferState } from '@/types/Http'
import FormInputAddress from '../FormInputAddress.vue'
import { useRedemptionStore } from '@/stores/RedemptionStore'
import { useAuthStore } from '@/stores/AuthStore'

const redemptionStore = useRedemptionStore()
const authStore = useAuthStore()

const props = defineProps<{
	asset?: Asset
}>()

const emit = defineEmits<{
	(event: 'close'): void
	(event: 'reload'): void
}>()

function close() {
	if (state.transfer.txError || state.transfer.txComplete) {
		emit('reload')
	} else {
		emit('close')
	}
}

const state = reactive<{
	transfer: TransferState
	showConfirmAddress: boolean
	showConfirmWarning: boolean
}>({
	transfer: {
		txInProgress: false,
		txComplete: false,
		txError: null,
		tx: null,
		errors: {} as any,
	},
	showConfirmAddress: false,
	showConfirmWarning: false,
})

onMounted(() => {
	if (authStore.user) {
		redemptionStore.redemptionInfo.user = {
			id: authStore.user.id,
			username: authStore.user.username,
			email: authStore.user.email,
		}
	}

	if (props.asset) {
		redemptionStore.redemptionInfo.assets.push({
			name: props.asset.name,
			asset_id: props.asset.asset_identifier,
			storefront_id: props.asset.storefront.id,
			quantity: 1,
		})
	}
})

onUnmounted(() => {
	redemptionStore.resetRedemptionAssets()
})

const isRedemptionInfoValid = computed(() => {
	return (
		redemptionStore.redemptionInfo.shippingInfo.shipping_name &&
		redemptionStore.redemptionInfo.shippingInfo.address_line1 &&
		redemptionStore.redemptionInfo.shippingInfo.city_locality &&
		redemptionStore.redemptionInfo.shippingInfo.state_region &&
		redemptionStore.redemptionInfo.shippingInfo.zip_postal_code &&
		!state.transfer.errors.zip_postal_code &&
		!state.transfer.errors?.phone
	)
})

async function redeemAsset() {
	if (!isRedemptionInfoValid) {
		return
	}

	if (!state.showConfirmAddress) {
		state.showConfirmAddress = true
		return
	}

	if (!state.showConfirmWarning) {
		state.showConfirmWarning = true
		return
	}

	state.transfer.txInProgress = true

	try {
		const res = await redemptionStore.redeemAssets()

		state.transfer.txInProgress = false

		if (res?.success) {
			state.transfer.txComplete = true
		} else {
			state.transfer.txError = res
		}
	} catch (e) {
		const err = e as AxiosError<Error>
		state.transfer.txError = err.response?.data.message || 'An unexpected error occurred.'
		console.error(err)
		state.transfer.txInProgress = false
	}
}

const upcomingTuesdayDate = computed(() => {
	// Calculate next Tuesday or use configuration
	const today = new Date()
	const nextTuesday = new Date()
	nextTuesday.setDate(today.getDate() + ((9 - today.getDay()) % 7))
	return nextTuesday.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
})
</script>
