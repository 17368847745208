import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faArrowLeft,
	faArrowRight,
	faBullseye,
	faBullseyePointer,
	faCalendarXmark,
	faCashRegister,
	faGavel,
	faGift,
	faGlobe,
	faHand,
	faPaperPlane,
	faShop,
	faStore,
	faTruck,
} from '@awesome.me/kit-428607b2fd/icons/classic/light'

library.add(
	faArrowLeft,
	faArrowRight,
	faBullseye,
	faBullseyePointer,
	faCalendarXmark,
	faCashRegister,
	faGavel,
	faGift,
	faGlobe,
	faHand,
	faPaperPlane,
	faShop,
	faStore,
	faTruck,
)
